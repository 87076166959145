@import url('https://fonts.googleapis.com/css2?family=Lexend&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rosarivo&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bentham&display=swap');

* {
    margin: 0;
    padding: 0;

    font-family: 'Bentham', sans-serif;
    font-weight: 300;
}

@media (min-width: 720px) {
    .categories {
        display: grid;
        grid-template-columns: 33% 33% 33%;
    
        gap: 0.5rem;
    
        margin: 1rem 0;
    }

    .container {
        width: 600px;
        margin: 0 auto;
    }

    .contact .container {
        display: flex;
        flex-direction: column;
    
        height: 100vh;
    
        justify-content: center;
        align-items: center;
    }

    .grid {
        display: grid;
        grid-template-columns: 50% 50%;

        gap: 1rem;
        margin: 2.5rem 0;
    }
}

@media (min-width: 1080px) {
    .categories {
        display: grid;
        grid-template-columns: 33% 33% 33%;
    
        gap: 0.5rem;
    
        margin: 1rem 0;
    }

    .container {
        width: 1080px;
        margin: 0 auto;
    }

    .contact .container {
        display: flex;
        flex-direction: column;
    
        height: 100vh;
    
        justify-content: center;
        align-items: center;
    }

    .grid {
        display: grid;
        grid-template-columns: 50% 50%;
    
        gap: 1rem;
        margin: 2.5rem 0;
    }

}

@media (max-width: 720px) {
    .categories {
        display: flex;
        flex-direction: column;
    
        margin: 1rem 0;
    }

    .container {
        width: 90%;
        margin: 0 auto;
    }

    .contact .container {
        display: flex;
        flex-direction: column;

        height: 100vh;

        padding-top: 3rem;
    
        justify-content: center;
        align-items: center;
    }

    .grid {
        display: grid;
        grid-template-rows: 60% 40%;
    
        gap: 2rem;
        margin: 1rem 0;
    }
}

span {
    color: bisque;
}

a {
    text-decoration: none;
}

a:hover {
    color: rgb(216, 197, 168);
}

header {
    position: fixed;

    left: 50%;
    transform: translate(-50%, 0%);

    width: 100%;

    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
}

header .container {
    color: white;

    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 1rem 0;
}

header .container img {
    height: 48px;  
}

nav {
    display: flex;
    gap: 2rem;
}

nav > * {
    color: white;
    text-decoration: none;
}

footer {
    position: fixed;

    width: 100%;
    left: 50%;
    bottom: 0%;

    padding: 1rem 0;

    transform: translate(-50%, 0%);
}

footer .container {
    display: flex;

    flex-direction: row;
    justify-content: space-between;
}

footer .container > * {
    color: #FFFFFF50;
    font-size: 14px;
}

button {
    background-color: transparent;
    color: white;

    width: 200px;

    border: 3px rgb(216, 197, 168) solid;

    padding: 1rem;
    margin: 0.25rem;

    font-size: 16px;

    transition: all 0.25s;
}

button:hover {
    cursor: pointer;

    background-color: rgb(216, 197, 168);
    color: black;
}

iframe {
    width: 100%;
    height: 100%;
}

.home, .contact, .catalogue {
    background: linear-gradient(rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)), url('../../public/media/home.jpg');
    background-repeat: no-repeat;
    background-size: cover;

    color: white;
}

.home {
    display: flex;
    flex-direction: column;

    height: 100vh;

    justify-content: center;
}

.title {
    font-size: 48px;
}

.catalogue {
    display: flex;
    flex-direction: column;

    height: 100vh;

    justify-content: center;
    align-items: center;
    text-align: center;
}

.social {
    display: flex;
    gap: 1.5rem;
    margin: 1rem 0;
}

.social > * {
    color: white;
    font-size: 24px;
}

.social > *:hover {
    cursor: pointer;
    color: rgb(216, 197, 168);
}

.grid div:first-child {
    text-align: center;
}

.grid > div > .details {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.description {
    color: #FFFFFF80;
    margin: 0.25rem 0 0.75rem;
}

.category {
    display: flex;

    background-color: transparent;
    color: white;

    border: 3px rgb(216, 197, 168) solid;

    width: 8rem;
    height: 2rem; 

    justify-content: center;
    align-items: center;

    padding: 0.5rem 1rem;
    margin: 0.25rem;

    font-size: 16px;

    transition: all 0.25s;
}

.category:hover {
    padding: 0.75rem 1.25rem;
    margin: 0;

    background-color: rgb(216, 197, 168);
    color: black;
}

.page-link {
    color: white;

    transition: all 0.25s;
}

.range {
    display: flex;

    flex-direction: column;
    align-items: center;

    background-color: #000000e5;

    height: 100vh;

    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('../../public/media/home.jpg');
    background-size: cover;
    background-attachment: fixed;

    overflow-y: scroll;

    color: #FFFFFF;
}

.range > * {
    margin-top: 7.5rem;

    text-align: center;
}

.items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    gap: 1rem;
    margin: 1rem 0 5rem;

    text-align: center;
}

.items > * > img {
    width: 256px;
}

.items > * > p > span {
    color: #FFFFFF80;
    margin-top: 0.5rem;
}